(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/generate-sportkryss-number.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/generate-sportkryss-number.js');
"use strict";


const {
  SPORTKRYSS_SIZE
} = svs.components.tipsen.engine.constants;

const generateSportkryssNumber = optPrefix => {
  let sportkryssNumber = '';
  if (optPrefix && typeof optPrefix === 'string') {
    sportkryssNumber = optPrefix;
  }

  const integerArr = Array.from(sportkryssNumber).filter(char => !Number.isNaN(Number(char)));
  for (let i = integerArr.length; i < SPORTKRYSS_SIZE; i++) {
    integerArr[i] = svsRandom.getRandomInt(0, 9);
  }
  return integerArr.join('');
};
setGlobal('svs.components.tipsen.engine.utils.generateSportkryssNumber', generateSportkryssNumber);

 })(window);