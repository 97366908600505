(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/definitions/challenge.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/definitions/challenge.js');
"use strict";

let makeXpertSizes;
let productIds;
let EventTypeId;
let constants;
let brandMapping;
if (svs.isServer) {
  constants = require('../constants.es6');
  makeXpertSizes = require('../utils/make-xpert-sizes.es6');
  productIds = require('trinidad-core').core.components.require('utils', 'products').productIds;
  EventTypeId = require('../../../../../sportinfo/common/assets/javascripts/constants.es6').EventTypeId;
  brandMapping = require('../../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6');
} else {
  makeXpertSizes = svs.components.tipsen.engine.utils.makeXpertSizes;
  productIds = svs.utils.products.productIds;
  EventTypeId = svs.components.sportinfo.common.constants.EventTypeId;
  constants = svs.components.tipsen.engine.constants;
  brandMapping = svs.components.lbUtils.brandMapping.fn;
}
const challengeDefinition = {
  competitions: [],
  outcomes: {
    eventTypeId: EventTypeId.EVENT_1X2,
    outcomeCount: 3,
    outcomeGroupCount: 1,
    outcomeHeatmap: {}
  },
  rSystems: [],
  uSystems: [],
  reduction: [],
  systems: [constants.systems.SYSTEM_MSYS],
  betAmounts: [5, 10, 20, 30, 50],
  maxBetAmount: 50,
  weeks: [1],
  sportkryssFactors: [0],
  sportkryssBase: 10,
  addonPixSize: [0],
  maxSystemSize: 4374,
  drawProductIds: [productIds.CHALLENGE],
  xpertMsys: makeXpertSizes(8, 8, 2, 108),
  xpertMsysSpecialValues: [4, 12, 24, 36, 64, 128, 192, 256],
  quickPlayPixSizes: [],
  displayName: brandMapping.getProductDisplayName(productIds.CHALLENGE),
  brandName: brandMapping.getSiteBrand(productIds.CHALLENGE),
  brandLogo: brandMapping.getProductBrand(productIds.CHALLENGE),
  getDisplayNameByDraw: draw => brandMapping.getProductDisplayNameByDraw(draw, productIds.CHALLENGE),
  getBrandNamesByDraw: draw => brandMapping.getProductBrandsByDraw(draw, productIds.CHALLENGE),
  getBrandLogoByDraw: draw => brandMapping.getProductBrandByDraw(draw, productIds.CHALLENGE),
  getBrandMenuColorByDraw: draw => brandMapping.getProductBrandMenuColorByDraw(draw, productIds.CHALLENGE),
  maxExternalSystemSize: 4374,
  features: {
    marketplace: true,
    singles: false,
    marketplaceTeam: false
  },
  getRelatedProduct: () => null,
  getTransfer: () => null,
  pixSystems: [],
  minGroupAmount: 10,
  minStake: 5,
  hasGuaranteedJackpot: false,
  hasJackpot: false,
  tipsinfoSupport: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.StartOdds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Matchstart, constants.TipsinfoTypes.Favourites, constants.TipsinfoTypes.Mutuals, constants.TipsinfoTypes.Ministat, constants.TipsinfoTypes.Compressed],
  tipsinfoSupportResult: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Favourites],
  tipsinfoSupportMyBets: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Favourites],
  loadDrawsApiPath: 'challenge',
  moduleEngine: 'challenge',
  defaultCouponValues: {
    betAmount: 1,
    reduceModeCount: undefined,
    pixSize: 20
  },
  pixType: 'Challenge',
  pixSystem: constants.PixSystem.Xperten,
  externalChunkSize: {
    SROW: 0,
    MSYS: 0,
    SROW_MARKETPLACE_GROUP: 0,
    MSYS_MARKETPLACE_GROUP: 0
  },
  couponSettings: [],
  maxWagerAmount: 0
};
if (svs.isServer) {
  module.exports = challengeDefinition;
} else {
  setGlobal('svs.components.tipsen.engine.definitions.challenge', challengeDefinition);
}

 })(window);