(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/make-engine.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/make-engine.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  constants
} = svs.components.tipsen.engine;
const initialState = {
  productId: 0,
  selectedDraw: -1,
  betAmounts: [1],
  selectedBetAmount: 0,
  coupon: [],
  systems: [constants.systems.SYSTEM_MSYS],
  selectedSystem: 0,
  maxDepositPerRound: 20000
};
const requiredProps = ['maxSystemSize'];


const makeEngineState = engineConfig => {
  let state;
  if (typeof engineConfig === 'function') {
    state = engineConfig();
  } else {
    state = engineConfig;
  }
  const missingProp = requiredProps.filter(prop => state[prop] === undefined).pop();
  if (missingProp) {
    throw new Error("missing required property: ".concat(missingProp));
  }
  return _objectSpread(_objectSpread({}, initialState), state);
};
setGlobal('svs.components.tipsen.engine.makeEngineState', makeEngineState);

 })(window);