(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/helpers.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/helpers.js');
"use strict";

const {
  useCallback
} = React;
const {
  generateSportPixCoupon,
  generateSportPixCouponBoard
} = svs.components.tipsen.engine.actions;
const useGenerateSportPixCouponAction = () => useCallback(generateSportPixCoupon, []);
const useGenerateSportPixCouponBoardAction = () => useCallback(generateSportPixCouponBoard, []);
setGlobal('svs.components.tipsen.engine.utils.helpers', {
  useGenerateSportPixCouponAction,
  useGenerateSportPixCouponBoardAction
});

 })(window);