(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/make-systems-list.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/make-systems-list.js');
"use strict";

let RSYSTEMS;
let USYSTEMS;
if (svs.isServer) {
  RSYSTEMS = require('../../../../../sport/systems/api').RSYSTEMS;
  USYSTEMS = require('../../../../../sport/systems/api').USYSTEMS;
} else {
  RSYSTEMS = svs.components.sport.systems.RSYSTEMS;
  USYSTEMS = svs.components.sport.systems.USYSTEMS;
}
const blacklistedSystemsForTipsen = ['R8-3-504', 'R9-0-1356', 'U8-3-171'];
const makeSystemsList = systemType => {
  const system = {
    R: RSYSTEMS,
    U: USYSTEMS
  }[systemType];
  if (!system) {
    throw new Error("Unsupported system: ".concat(systemType));
  }
  return Object.keys(system).filter(systemNum => !blacklistedSystemsForTipsen.includes(system[systemNum])).sort((sysA, sysB) => {
    const [aFull, aHalf, aTot] = system[sysA].match(/(\d+)/g).map(Number);
    const [bFull, bHalf, bTot] = system[sysB].match(/(\d+)/g).map(Number);
    return aFull - bFull || aHalf - bHalf || aTot - bTot;
  }).map(Number);
};
if (svs.isServer) {
  module.exports = makeSystemsList;
} else {
  setGlobal('svs.components.tipsen.engine.utils.makeSystemsList', makeSystemsList);
}

 })(window);