(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/make-xpert-sizes.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/make-xpert-sizes.js');
"use strict";

const makeXpertSizes = (maxHalf, maxFull, minSize, maxSize) => {
  const sizes = [];
  for (let halfHedges = 0; halfHedges < maxHalf; ++halfHedges) {
    for (let fullHedges = 0; fullHedges < maxFull; ++fullHedges) {
      const size = 1 * 2 ** halfHedges * 3 ** fullHedges;
      if (size >= minSize && size <= maxSize) {
        sizes.push(size);
      }
    }
  }
  sizes.sort((a, b) => a - b);
  return sizes;
};
if (svs.isServer) {
  module.exports = makeXpertSizes;
} else {
  setGlobal('svs.components.tipsen.engine.utils.makeXpertSizes', makeXpertSizes);
}

 })(window);