(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/constants.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const systems = {
  SYSTEM_MSYS: 'MSYS',
  SYSTEM_RSYS: 'RSYS',
  SYSTEM_USYS: 'USYS',
  SYSTEM_REDUCERA_FRITT: 'REDUCERA_FRITT'
};
const reduceModeRange = {
  MIN: 20,
  MAX: 2200
};
const couponStatus = {
  COUPON_VALID: 'VALID',
  COUPON_INVALID_NO_COMPLETE_BOARD: 'INVALID_NO_COMPLETE_BOARD',
  COUPON_INVALID_MAX_SIZE_EXCEEDED: 'INVALID_MAX_SIZE_EXCEEDED',
  COUPON_INVALID_REDUCE_COUNT_TOO_GREAT: 'INVALID_REDUCE_COUNT_TOO_GREAT',
  COUPON_INVALID_REDUCE_COUNT_NOT_IN_RANGE: 'INVALID_REDUCE_COUNT_NOT_IN_RANGE',
  COUPON_INVALID_WRONG_HEDGES: 'INVALID_WRONG_HEDGES',
  COUPON_INVALID_WRONG_USIGNS: 'INVALID_WRONG_USIGNS',
  COUPON_INVALID_BELOW_GROUP_AMOUNT: 'INVALID_BELOW_GROUP_AMOUNT',
  COUPON_PRICE_IS_LOWER_THAN_MINSTAKE: 'COUPON_PRICE_IS_LOWER_THAN_MINSTAKE',
  COUPON_DONT_MATCH_COMPETITION_SYSTEMSIZE: 'COUPON_DONT_MATCH_COMPETITION_SYSTEMSIZE',
  COUPON_INVALID_ZERO_ROWS_REDUCTION: 'COUPON_INVALID_ZERO_ROWS_REDUCTION'
};
const gameBoardSystemType = {
  MSYS: 'MSYS',
  SROW: 'SROW',
  RSYS: 'RSYS',
  USYS: 'USYS',
  UROW: 'UROW'
};
const couponTypes = {
  COUPON_STANDARD: 'standard',
  COUPON_SINGLEROWS: 'singlerows',
  COUPON_XPERT: 'pix',
  COUPON_EXTERNAL_SYSTEM: 'external_system'
};
const boardType = {
  BOARD_MULTIPLE: 'BOARD_MULTIPLE',
  BOARD_SINGLE: 'BOARD_SINGLE'
};
const xpertWagerSystemTypes = {
  XPERT_WAGER_MSYS: 'MSYS',
  XPERT_WAGER_RSYS: 'RSYS',
  XPERT_WAGER_SROW: 'SROW'
};
const SportPixWagerSystemTypes = {
  MSYS: 'MSys',
  RSYS: 'RSys',
  USYS: 'USys',
  SROW: 'SRow'
};
const quickpickProp = {
  QUICKPICK_WEIGHTED: 'WEIGHTEDQP',
  QUICKPICK_PARTIAL: 'PARTIALQP',
  QUICKPICK_MODIFIED: 'MODIFIEDQP'
};
const betProp = {
  BET_PROP_REDUCERA_FRITT: 'REDUCERA_FRITT',
  BET_PROP_MARKETPLACE: 'GROUP',
  BET_PROP_MARKETPLACE_RETAILER: 'GROUP_B2C'
};
const BetslipCouponType = {
  Standard: 'Standard',
  USystem: 'USystem',
  RSystem: 'RSystem',
  ReduceraFritt: 'ReduceraFritt',
  Enkelrader: 'Enkelrader',
  ExternaSystemspel: 'ExternaSystemspel',
  Competition: 'Competition'
};
const SPORTKRYSS_SIZE = 7;
const TipsinfoTypes = {
  Odds: 'odds',
  StartOdds: 'startOdds',
  SvenskaFolket: 'svenskaFolket',
  TioTidningar: 'tioTidningar',
  Matchstart: 'matchStart',
  OutcomeLabel: 'outcomeLabel',
  Favourites: 'favourites',
  Mutuals: 'mutuals',
  Analys: 'analys',
  Ministat: 'ministat',
  Compressed: 'compressed',
  OverUnder: 'overUnder'
};
const coupon = {
  Compressed: 'compressed'
};
const systemTypePresentation = {
  MSYS: {
    icon: 'enkelrader-tur',
    text: 'Standard'
  },
  RSYS: {
    icon: 'r-system',
    text: 'R-system'
  },
  USYS: {
    icon: 'u-system',
    text: 'U-system'
  },
  REDUCERA_FRITT: {
    icon: 'free-reduce',
    text: 'Reducera Fritt'
  },
  SROW: {
    icon: 'enkelrader',
    text: 'Enkelrader'
  },
  PIX: {
    icon: 'pix',
    text: 'PIX'
  }
};
const wagerSystemTypes = {
  SROW: 'SROW',
  MSYS: 'MSYS',
  USYS: 'USYS',
  UROW: 'UROW',
  RSYS: 'RSYS',
  REDUCERA_FRITT: 'REDUCERA_FRITT',
  OWNROWS: 'EGNARADER',
  ENKELRADER: 'ENKELRADER',
  XPERTEN: 'XPERTEN'
};
const externalSystemsType = {
  MATH: 'math',
  SINGLE: 'single',
  NONE: 'none'
};
const externalSystemsBoardsType = {
  [externalSystemsType.MATH]: 'BOARD_MULTIPLE',
  [externalSystemsType.SINGLE]: 'BOARD_SINGLE',
  [externalSystemsType.NONE]: 'BOARD_SINGLE'
};
const PixSystem = {
  Xperten: 'xperten',
  SportPix: 'sportPix'
};
const RelatedProductType = {
  PIX: 'pix'
};
const engineCouponSelectorType = {
  MATRIX: 'MATRIX',
  BINARY: 'BINARY'
};
const ReductionOutcome = {
  HOME: 'HOME',
  DRAW: 'DRAW',
  AWAY: 'AWAY'
};
const ReductionScore = [ReductionOutcome.HOME, ReductionOutcome.DRAW, ReductionOutcome.AWAY].reduce((reductions, reduction, position) => _objectSpread(_objectSpread({}, reductions), {}, {
  [reduction]: 1 << position
}), {});
const ReductionScoreValueToOutcome = Object.entries(ReductionScore).reduce((outcomes, _ref) => {
  let [outcome, value] = _ref;
  return _objectSpread(_objectSpread({}, outcomes), {}, {
    [value]: outcome
  });
}, {});
const engineConstants = {
  externalSystemsType,
  externalSystemsBoardsType,
  systems,
  reduceModeRange,
  gameBoardSystemType,
  couponStatus,
  couponTypes,
  xpertWagerSystemTypes,
  boardType,
  quickpickProp,
  betProp,
  BetslipCouponType,
  SPORTKRYSS_SIZE,
  TipsinfoTypes,
  systemTypePresentation,
  wagerSystemTypes,
  PixSystem,
  RelatedProductType,
  coupon,
  engineCouponSelectorType,
  ReductionScore,
  ReductionScoreValueToOutcome,
  SportPixWagerSystemTypes
};
if (svs.isServer) {
  module.exports = engineConstants;
} else {
  setGlobal('svs.components.tipsen.engine.constants', engineConstants);
}

 })(window);