(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/definitions/europatipset.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/definitions/europatipset.js');
"use strict";

let makeXpertSizes;
let makeSystemsList;
let isSelfService;
let productIds;
let EventTypeId;
let constants;
let brandMapping;
let getSystemNumFromSystemLabel;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  constants = require('../constants.es6');
  makeXpertSizes = require('../utils/make-xpert-sizes.es6');
  makeSystemsList = require('../utils/make-systems-list.es6');
  isSelfService = trinidad.config.options.isSelfService;
  productIds = trinidad.components.require('utils', 'products').productIds;
  EventTypeId = require('../../../../../sportinfo/common/assets/javascripts/constants.es6').EventTypeId;
  brandMapping = require('../../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6');
  getSystemNumFromSystemLabel = require('../../../../../sport/systems/api').getSystemNumFromSystemLabel;
} else {
  var _svs$core$config$data;
  makeXpertSizes = svs.components.tipsen.engine.utils.makeXpertSizes;
  makeSystemsList = svs.components.tipsen.engine.utils.makeSystemsList;
  isSelfService = (_svs$core$config$data = svs.core.config.data.svsconfig) === null || _svs$core$config$data === void 0 ? void 0 : _svs$core$config$data.isSelfService;
  productIds = svs.utils.products.productIds;
  EventTypeId = svs.components.sportinfo.common.constants.EventTypeId;
  constants = svs.components.tipsen.engine.constants;
  brandMapping = svs.components.lbUtils.brandMapping.fn;
  getSystemNumFromSystemLabel = svs.components.sport.systems.getSystemNumFromSystemLabel;
}
const europatipsetDefinition = {
  competitions: [{
    system: constants.systems.SYSTEM_MSYS,
    systemSize: 64
  }],
  outcomes: {
    eventTypeId: EventTypeId.EVENT_1X2,
    outcomeCount: 3,
    outcomeGroupCount: 1,
    outcomeHeatmap: {}
  },
  rSystems: makeSystemsList('R'),
  uSystems: makeSystemsList('U'),
  reduction: [],
  systems: [constants.systems.SYSTEM_MSYS, constants.systems.SYSTEM_RSYS, constants.systems.SYSTEM_USYS],
  betAmounts: [],
  maxBetAmount: 0,
  weeks: [1],
  sportkryssFactors: [0],
  sportkryssBase: 10,
  addonPixSize: [0],
  maxSystemSize: 41472,
  drawProductIds: [productIds.EUROPATIPSET],
  xpertMsys: makeXpertSizes(13, 13, 4, 10000),
  xpertMsysSpecialValues: [16, 24, 36, 64, 96, 128, 192, 256, 384],
  quickPlayPixSizes: [16, 32, 64, 96, 128, 192, 256, 512],
  displayName: brandMapping.getProductDisplayName(productIds.EUROPATIPSET),
  brandName: brandMapping.getSiteBrand(productIds.EUROPATIPSET),
  brandLogo: brandMapping.getProductBrand(productIds.EUROPATIPSET),
  getDisplayNameByDraw: draw => brandMapping.getProductDisplayNameByDraw(draw, productIds.EUROPATIPSET),
  getBrandNamesByDraw: draw => brandMapping.getProductBrandsByDraw(draw, productIds.EUROPATIPSET),
  getBrandLogoByDraw: draw => brandMapping.getProductBrandByDraw(draw, productIds.EUROPATIPSET),
  getBrandMenuColorByDraw: draw => brandMapping.getProductBrandMenuColorByDraw(draw, productIds.EUROPATIPSET),
  maxExternalSystemSize: 41472,
  features: {
    marketplace: true,
    singles: true,
    marketplaceTeam: false
  },
  getRelatedProduct: () => null,
  getTransfer: () => ({
    product: productIds.T8EXTRA,
    drawsApi: 'topptipsetfamily'
  }),
  pixSystems: [getSystemNumFromSystemLabel('R7-0-36'), getSystemNumFromSystemLabel('R8-0-81'), getSystemNumFromSystemLabel('R9-0-222'), getSystemNumFromSystemLabel('R10-0-567'), getSystemNumFromSystemLabel('R11-0-729')],
  minGroupAmount: 10,
  minStake: 1,
  hasGuaranteedJackpot: false,
  hasJackpot: true,
  tipsinfoSupport: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.StartOdds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Matchstart, constants.TipsinfoTypes.Favourites, constants.TipsinfoTypes.Mutuals, constants.TipsinfoTypes.Analys, constants.TipsinfoTypes.Ministat, constants.TipsinfoTypes.Compressed],
  tipsinfoSupportResult: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Favourites],
  tipsinfoSupportMyBets: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Favourites],
  loadDrawsApiPath: 'europatipset',
  moduleEngine: 'europatipset',
  defaultCouponValues: {
    betAmount: 1,
    reduceModeCount: 20,
    pixSize: 96
  },
  pixType: '1X2',
  pixSystem: constants.PixSystem.Xperten,
  externalChunkSize: {
    SROW: 2400,
    MSYS: 1000,
    SROW_MARKETPLACE_GROUP: 2200,
    MSYS_MARKETPLACE_GROUP: 950
  },
  couponSettings: [constants.coupon.Compressed],
  maxWagerAmount: 0
};
if (!isSelfService) {
  europatipsetDefinition.systems.push(constants.systems.SYSTEM_REDUCERA_FRITT);
}
if (svs.isServer) {
  module.exports = europatipsetDefinition;
} else {
  setGlobal('svs.components.tipsen.engine.definitions.europatipset', europatipsetDefinition);
}

 })(window);