(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/engine-provider.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/engine-provider.js');
"use strict";


const {
  makeEngineState,
  actions,
  definitions
} = svs.components.tipsen.engine;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  useCallback,
  useMemo,
  createContext
} = React;
const EngineProviderContext = createContext();
const EngineProvider = _ref => {
  let {
    children
  } = _ref;
  const currentEngine = ReactRedux.useSelector(selectEngineDefinition);
  const {
    useDispatch
  } = ReactRedux;
  const dispatch = useDispatch();
  const switchEngine = useCallback(engineName => {
    const initialState = makeEngineState(definitions[engineName]);
    if (engineName !== currentEngine) {
      dispatch(actions.switchEngine({
        engineName,
        initialState
      }));
    }
  }, [currentEngine, dispatch]);
  const value = useMemo(() => ({
    switchEngine,
    currentEngine
  }), [switchEngine, currentEngine]);
  return React.createElement(EngineProviderContext.Provider, {
    value: value
  }, children);
};
setGlobal('svs.components.tipsen.engine.EngineProvider', EngineProvider);
setGlobal('svs.components.tipsen.engine.EngineProviderContext', EngineProviderContext);

 })(window);