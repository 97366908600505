(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/create-coupons.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/create-coupons.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  COUPON_STANDARD,
  COUPON_SINGLEROWS,
  COUPON_EXTERNAL_SYSTEM,
  COUPON_XPERT
} = svs.components.tipsen.engine.constants.couponTypes;
const {
  SYSTEM_MSYS
} = svs.components.tipsen.engine.constants.systems;
const {
  engineCouponSelectorType
} = svs.components.tipsen.engine.constants;
const {
  BOARD_MULTIPLE,
  BOARD_SINGLE
} = svs.components.tipsen.engine.constants.boardType;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  generateSportkryssNumber
} = svs.components.tipsen.engine.utils;
const {
  createNewBitmap
} = svs.components.sport.tipsenShared.bitmap;
const createMatrixBoard = (draw, engineDefinition) => {
  var _engineDefinition$out;
  const sportType = draw.sportType;
  let outcomeCount;
  const heatmapMaxValue = (_engineDefinition$out = engineDefinition.outcomes.outcomeHeatmap[sportType]) === null || _engineDefinition$out === void 0 ? void 0 : _engineDefinition$out.maxValue;
  if (heatmapMaxValue) {
    outcomeCount = heatmapMaxValue + 1;
  } else {
    outcomeCount = engineDefinition.outcomes.outcomeCount;
  }
  const signs = new Array(outcomeCount).fill(false);
  const userSigns = new Array(outcomeCount).fill(false);
  const signDistribution = new Array(outcomeCount).fill(0);
  return {
    signs: draw.drawEvents.map(() => signs.slice()),
    userSigns: draw.drawEvents.map(() => userSigns.slice()),
    signDistribution: draw.drawEvents.map(() => signDistribution.slice()),
    uSigns: draw.drawEvents.map(() => null),
    mSigns: draw.drawEvents.map(() => false)
  };
};
const createScoreBoard = (draw, engineDefinition) => {
  var _engineDefinition$out2;
  const eventCount = draw.drawEvents.length;
  const reductionValue = engineDefinition.reduction.reduce((sum, value) => sum + value, 0);
  const sportType = draw.sportType;
  const outcomeCount = ((_engineDefinition$out2 = engineDefinition.outcomes.outcomeHeatmap[sportType]) === null || _engineDefinition$out2 === void 0 ? void 0 : _engineDefinition$out2.maxValue) + 1;
  const reduction = new Array(eventCount).fill(reductionValue);
  return {
    signs: draw.drawEvents.map(() => [createNewBitmap(outcomeCount), createNewBitmap(outcomeCount)]),
    userSigns: draw.drawEvents.map(() => [createNewBitmap(outcomeCount), createNewBitmap(outcomeCount)]),
    reduction,
    uSigns: [],
    mSigns: []
  };
};
const createBoard = (draw, type, engineDefinition) => {
  const eventTypeId = engineDefinition.outcomes.eventTypeId;
  const engineType = eventTypeId === EventTypeId.RESULT ? engineCouponSelectorType.BINARY : engineCouponSelectorType.MATRIX;
  let board;
  if (engineType === engineCouponSelectorType.MATRIX) {
    board = createMatrixBoard(draw, engineDefinition);
  } else {
    board = createScoreBoard(draw, engineDefinition);
  }
  return _objectSpread({
    type,
    pixSize: null,
    pixVariant: null
  }, board);
};
const createStandardCoupon = _ref => {
  var _engineDefinition$def, _engineDefinition$def2, _engineDefinition$sys, _engineDefinition$def3, _engineDefinition$def4;
  let {
    draw,
    engineDefinition
  } = _ref;
  const eventTypeId = engineDefinition.outcomes.eventTypeId;
  const engineType = eventTypeId === EventTypeId.RESULT ? engineCouponSelectorType.BINARY : engineCouponSelectorType.MATRIX;
  return {
    productId: draw.productId,
    drawNumber: draw.drawNumber,
    engineType,
    boards: [createBoard(draw, BOARD_MULTIPLE, engineDefinition)],
    isModified: false,
    betAmount: (_engineDefinition$def = (_engineDefinition$def2 = engineDefinition.defaultCouponValues) === null || _engineDefinition$def2 === void 0 ? void 0 : _engineDefinition$def2.betAmount) !== null && _engineDefinition$def !== void 0 ? _engineDefinition$def : 1,
    sportkryss: 0,
    sportkryssNumber: generateSportkryssNumber(),
    selectedAddonPixIndex: 0,
    week: 0,
    uSystem: null,
    rSystem: null,
    selectedSystem: engineDefinition.systems.indexOf(SYSTEM_MSYS),
    lastSelectedSystemType: engineDefinition.systems.indexOf((_engineDefinition$sys = engineDefinition.systems.filter(sys => sys !== SYSTEM_MSYS)[0]) !== null && _engineDefinition$sys !== void 0 ? _engineDefinition$sys : 0),
    reduceModeCount: (_engineDefinition$def3 = (_engineDefinition$def4 = engineDefinition.defaultCouponValues) === null || _engineDefinition$def4 === void 0 ? void 0 : _engineDefinition$def4.reduceModeCount) !== null && _engineDefinition$def3 !== void 0 ? _engineDefinition$def3 : 0
  };
};
const createSingleRowsCoupon = _ref2 => {
  var _engineDefinition$def5, _engineDefinition$def6, _engineDefinition$def7, _engineDefinition$def8;
  let {
    draw,
    engineDefinition
  } = _ref2;
  const eventTypeId = engineDefinition.outcomes.eventTypeId;
  const engineType = eventTypeId === EventTypeId.RESULT ? engineCouponSelectorType.BINARY : engineCouponSelectorType.MATRIX;
  const singleRowsCoupon = {
    productId: draw.productId,
    drawNumber: draw.drawNumber,
    engineType,
    boards: [],
    isModified: false,
    betAmount: (_engineDefinition$def5 = (_engineDefinition$def6 = engineDefinition.defaultCouponValues) === null || _engineDefinition$def6 === void 0 ? void 0 : _engineDefinition$def6.betAmount) !== null && _engineDefinition$def5 !== void 0 ? _engineDefinition$def5 : 1,
    sportkryss: 0,
    sportkryssNumber: generateSportkryssNumber(),
    week: 0,
    uSystem: null,
    rSystem: null,
    selectedSystem: engineDefinition.systems.indexOf(SYSTEM_MSYS),
    reduceModeCount: (_engineDefinition$def7 = (_engineDefinition$def8 = engineDefinition.defaultCouponValues) === null || _engineDefinition$def8 === void 0 ? void 0 : _engineDefinition$def8.reduceModeCount) !== null && _engineDefinition$def7 !== void 0 ? _engineDefinition$def7 : 0
  };
  for (let i = 0; i < 12; ++i) {
    singleRowsCoupon.boards.push(createBoard(draw, BOARD_SINGLE, engineDefinition));
  }
  return singleRowsCoupon;
};
const createExternalCoupon = _ref3 => {
  let {
    draw,
    engineDefinition
  } = _ref3;
  return _objectSpread(_objectSpread({}, createSingleRowsCoupon({
    draw,
    engineDefinition
  })), {}, {
    isExternal: true,
    boards: []
  });
};
const createPixCoupon = _ref4 => {
  let {
    draw,
    engineDefinition
  } = _ref4;
  const eventTypeId = engineDefinition.outcomes.eventTypeId;
  const engineType = eventTypeId === EventTypeId.RESULT ? engineCouponSelectorType.BINARY : engineCouponSelectorType.MATRIX;
  return {
    productId: draw.productId,
    drawNumber: draw.drawNumber,
    engineType,
    boards: [createBoard(draw, BOARD_MULTIPLE, engineDefinition)],
    isModified: false,
    betAmount: 1,
    sportkryss: 0,
    sportkryssNumber: generateSportkryssNumber(),
    week: 0,
    uSystem: null,
    rSystem: null,
    selectedSystem: engineDefinition.systems.indexOf(SYSTEM_MSYS),
    reduceModeCount: undefined,
    selectedAddonPixIndex: 0
  };
};
const createCoupons = _ref5 => {
  let {
    draws,
    engineDefinition
  } = _ref5;
  const coupons = {};
  for (const draw of Object.values(draws)) {
    const drawId = "".concat(draw.productId, "_").concat(draw.drawNumber);
    if (draw.isLite) {
      const pixEngineDefintion = getDefinitionByProduct(draw.productId);
      const drawToUse = _objectSpread(_objectSpread({}, draw), {}, {
        drawEvents: new Array(13).fill(null)
      });
      coupons["".concat(drawId, "_").concat(COUPON_XPERT)] = createPixCoupon({
        draw: drawToUse,
        engineDefinition: pixEngineDefintion
      });
      continue;
    }
    coupons["".concat(drawId, "_").concat(COUPON_STANDARD)] = createStandardCoupon({
      draw,
      engineDefinition
    });
    coupons["".concat(drawId, "_").concat(COUPON_SINGLEROWS)] = createSingleRowsCoupon({
      draw,
      engineDefinition
    });
    coupons["".concat(drawId, "_").concat(COUPON_EXTERNAL_SYSTEM)] = createExternalCoupon({
      draw,
      engineDefinition
    });
  }
  return coupons;
};
setGlobal('svs.components.tipsen.engine', {
  createCoupons,
  createPixCoupon,
  createExternalCoupon,
  createSingleRowsCoupon,
  createStandardCoupon
});

 })(window);