(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/is-valid-sportkryss-number.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/is-valid-sportkryss-number.js');
"use strict";

const {
  SPORTKRYSS_SIZE
} = svs.components.tipsen.engine.constants;
const isValidSportkryssNumber = value => {
  if (typeof value !== 'string') {
    return false;
  }
  if (value.length !== SPORTKRYSS_SIZE) {
    return false;
  }
  for (const char of value) {
    if (!/^\d+$/.test(char)) {
      return false;
    }
  }
  return true;
};
setGlobal('svs.components.tipsen.engine.utils.isValidSportkryssNumber', isValidSportkryssNumber);

 })(window);