(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/xpertSystems/8-13-signs.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/xpertSystems/8-13-signs.js');
"use strict";

const xpertSizes = [4, 6, 8, 12, 16, 20, 24, 27, 32, 36, 48, 54, 64, 72, 96, 100, 108, 120, 128, 144, 150, 180, 192, 200, 216, 240, 256, 270, 288, 320, 384, 432, 480, 512, 540, 576, 640, 768, 864, 960, 1024, 1152, 1280, 1296, 1440, 1536, 1728, 1792, 1920, 2048, 2560, 2688, 2880, 3072, 3456, 3584, 4096, 4608, 5120, 5760, 6912, 7680, 8192, 9072, 9216, 9408];
const toExport = {
  xpertSizes
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.tipsen.engine.xpertSystems.signs8rows13', toExport);
}


 })(window);