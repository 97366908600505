(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/xpertSystems/3-13-signs.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/xpertSystems/3-13-signs.js');
"use strict";

const xpertSizes = [4, 6, 8, 9, 12, 16, 18, 24, 27, 32, 36, 48, 54, 64, 72, 81, 96, 108, 128, 144, 162, 192, 216, 243, 256, 288, 324, 384, 432, 486, 512, 576, 648, 729, 768, 864, 972, 1024, 1152, 1296, 1458, 1536, 1728, 1944, 2048, 2187, 2304, 2592, 2916, 3072, 3456, 3888, 4096, 4374, 4608, 5184, 5832, 6144, 6561, 6912, 7776, 8192, 8748, 9216];
const toExport = {
  xpertSizes
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.tipsen.engine.xpertSystems.signs3rows13', toExport);
}



 })(window);