(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/sign-distribution-conversion.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/sign-distribution-conversion.js');
"use strict";

const signDistributionConversion = signDistribution => signDistribution.map(weights => {
  let total = 0;
  for (const weight of weights) {
    total += weight;
  }
  if (total === 0) {
    return new Array(weights.length).fill(0);
  }
  const percentageWeights = [];
  for (let i = 0; i < weights.length; ++i) {
    const percent = weights[i] / total * 100;
    percentageWeights.push(percent);
  }
  return percentageWeights;
});
setGlobal('svs.components.tipsen.engine.utils.signDistributionConversion', signDistributionConversion);

 })(window);