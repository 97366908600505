(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/use-definition.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/use-definition.js');
"use strict";

const {
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const {
  selectDefinitionId
} = svs.components.tipsen.engine.selectors.engineSelectors;
const useDefinition = () => {
  const {
    definitions
  } = svs.components.tipsen.engine;
  const definitionId = useSelector(selectDefinitionId);
  return useMemo(() => definitions[definitionId], [definitionId, definitions]);
};
setGlobal('svs.components.tipsen.engine.utils.useDefinition', useDefinition);

 })(window);