(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/reducers/make-coupon-reducer.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/reducers/make-coupon-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  createReducer,
  isAnyOf
} = RTK;
const {
  createCoupons,
  createPixCoupon
} = svs.components.tipsen.engine;
const {
  clearCoupon,
  clearCouponBoard,
  toggleAlternative,
  toggleOutcome,
  toggleReduction,
  setExternalCoupon,
  selectBetAmount,
  setSportkryssFactorIndex,
  setSportkryssNumber,
  selectWeek,
  selectPixSize,
  placeWager,
  resetWithCouponValues,
  selectSystem,
  toggleMSign,
  generateSportPixCoupon,
  setCouponValues,
  setSignDistribution,
  setReduceModeCount,
  addCouponValues
} = svs.components.tipsen.engine.actions;
const {
  COUPON_EXTERNAL_SYSTEM,
  COUPON_XPERT
} = svs.components.tipsen.engine.constants.couponTypes;
const {
  SYSTEM_USYS,
  SYSTEM_MSYS
} = svs.components.tipsen.engine.constants.systems;
const {
  BOARD_MULTIPLE,
  BOARD_SINGLE
} = svs.components.tipsen.engine.constants.boardType;
const {
  QUICKPICK_WEIGHTED,
  QUICKPICK_PARTIAL,
  QUICKPICK_MODIFIED
} = svs.components.tipsen.engine.constants.quickpickProp;
const {
  loadOpenedDrawsByEngine,
  loadDrawsLite
} = svs.components.sport.drawsRedux.actions;
const definitions = svs.components.tipsen.engine.definitions;
const {
  relatedTypes
} = svs.components.sport.drawsRedux.constants;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  engineCouponSelectorType
} = svs.components.tipsen.engine.constants;
const {
  resetBitmap,
  toggleBitValue,
  toggleBitmapValue,
  hasBitmapValues,
  arrayToBitmap,
  combine
} = svs.components.sport.tipsenShared.bitmap;
const {
  products
} = svs.utils;
const helperGetSigns = (state, _ref) => {
  var _state$couponId$board, _state$couponId;
  let {
    couponId,
    board,
    event
  } = _ref;
  return (_state$couponId$board = (_state$couponId = state[couponId]) === null || _state$couponId === void 0 || (_state$couponId = _state$couponId.boards[board]) === null || _state$couponId === void 0 ? void 0 : _state$couponId.signs[event]) !== null && _state$couponId$board !== void 0 ? _state$couponId$board : null;
};
const helperGetMSign = (state, _ref2) => {
  var _state$couponId$board2, _state$couponId2;
  let {
    couponId,
    board,
    event
  } = _ref2;
  return (_state$couponId$board2 = (_state$couponId2 = state[couponId]) === null || _state$couponId2 === void 0 || (_state$couponId2 = _state$couponId2.boards[board]) === null || _state$couponId2 === void 0 ? void 0 : _state$couponId2.mSigns[event]) !== null && _state$couponId$board2 !== void 0 ? _state$couponId$board2 : null;
};
const helperToggleMSign = (state, _ref3) => {
  let {
    couponId,
    board,
    event
  } = _ref3;
  state[couponId].boards[board].mSigns[event] = !helperGetMSign(state, {
    couponId,
    board,
    event
  });
};
const setCouponValuesMatrix = _ref4 => {
  let {
    state,
    couponId,
    signs
  } = _ref4;
  for (let boardIndex = 0; boardIndex < Math.min(signs.length, state[couponId].boards.length); ++boardIndex) {
    state[couponId].boards[boardIndex].signs = state[couponId].boards[boardIndex].signs.map((element, index) => {
      if (signs[boardIndex].signs[index]) {
        return signs[boardIndex].signs[index];
      }
      return element;
    });

    if (signs[boardIndex].mSigns) {
      for (let x = 0; x < signs[boardIndex].mSigns.length; ++x) {
        const activeSigns = helperGetSigns(state, {
          couponId,
          board: boardIndex,
          event: x
        }).filter(sign => sign);
        let mSign = signs[boardIndex].mSigns[x];

        if (activeSigns.length < 2 && mSign) {
          mSign = false;
        }
        state[couponId].boards[boardIndex].mSigns[x] = mSign;
      }
    }

    state[couponId].boards[boardIndex].userSigns = state[couponId].boards[boardIndex].signs;
    state[couponId].boards[boardIndex].signDistribution = state[couponId].boards[boardIndex].signs.map(r => r.map(s => s ? 1 : 0));

    if (signs[boardIndex].uSigns) {
      state[couponId].boards[boardIndex].uSigns = signs[boardIndex].uSigns;
    }
    state[couponId].isModified = true;
  }
};
const setCouponValuesBinary = _ref5 => {
  let {
    state,
    couponId,
    signs,
    reduction
  } = _ref5;
  for (let boardIndex = 0; boardIndex < Math.min(signs.length, state[couponId].boards.length); ++boardIndex) {
    const board = state[couponId].boards[boardIndex];
    for (let eventIndex = 0; eventIndex < board.signs.length; ++eventIndex) {
      for (let outcomeGroupIndex = 0; outcomeGroupIndex < board.signs[eventIndex].length; ++outcomeGroupIndex) {
        const newValue = arrayToBitmap(signs[boardIndex].signs[eventIndex][outcomeGroupIndex]);
        board.signs[eventIndex][outcomeGroupIndex] = [newValue];
      }
    }
    if (reduction && reduction.length) {
      board.reduction = reduction[boardIndex];
    }
    state[couponId].isModified = true;
  }
};
const resetCouponMatrix = _ref6 => {
  let {
    state,
    couponId,
    keepUserSigns
  } = _ref6;
  for (const board of state[couponId].boards) {
    board.pixSize = null;
    board.pixVariant = null;
    if (!keepUserSigns) {
      for (const row of board.userSigns) {
        for (let i = 0; i < row.length; ++i) {
          row[i] = false;
        }
      }
    }
    for (const row of board.signs) {
      for (let i = 0; i < row.length; ++i) {
        row[i] = false;
      }
    }
    for (const row of board.signDistribution) {
      for (let i = 0; i < row.length; ++i) {
        row[i] = 0;
      }
    }
    for (let i = 0; i < board.mSigns.length; ++i) {
      board.mSigns[i] = false;
    }
    for (let i = 0; i < board.uSigns.length; ++i) {
      board.uSigns[i] = null;
    }
  }
};
const resetCouponBinary = _ref7 => {
  let {
    state,
    couponId,
    keepUserSigns,
    engineReductionValue
  } = _ref7;
  for (const board of state[couponId].boards) {
    if (engineReductionValue) {
      const newReduction = [];
      board.reduction.forEach(() => newReduction.push(engineReductionValue));
      board.reduction = newReduction;
    }
    board.pixSize = null;
    board.pixVariant = null;
    if (!keepUserSigns) {
      for (const eventRow of board.userSigns) {
        for (const row of eventRow) {
          resetBitmap(row);
        }
      }
    }
    for (const eventRow of board.signs) {
      for (const row of eventRow) {
        resetBitmap(row);
      }
    }
  }
};

const resetCoupon = _ref8 => {
  var _state$couponId3;
  let {
    state,
    couponId,
    keepUserSigns = false,
    resetFullCoupon = false,
    sportkryssNumber,
    reduceModeCount,
    betAmount
  } = _ref8;
  if (((_state$couponId3 = state[couponId]) === null || _state$couponId3 === void 0 ? void 0 : _state$couponId3.engineType) === engineCouponSelectorType.BINARY) {
    const productId = state[couponId].productId;
    const engineDefinition = getDefinitionByProduct(productId);
    const engineReductionValue = engineDefinition.reduction.reduce((sum, value) => sum + value, 0);
    resetCouponBinary({
      state,
      couponId,
      keepUserSigns,
      resetFullCoupon,
      reduceModeCount,
      betAmount,
      engineReductionValue
    });
  } else {
    resetCouponMatrix({
      state,
      couponId,
      keepUserSigns,
      resetFullCoupon,
      sportkryssNumber,
      reduceModeCount,
      betAmount
    });
  }
  if (resetFullCoupon) {
    state[couponId].betAmount = betAmount !== null && betAmount !== void 0 ? betAmount : 1;
    state[couponId].week = 0;
    state[couponId].sportkryss = 0;
    state[couponId].sportkryssNumber = sportkryssNumber;

    if (!state[couponId].selectedSystem === 3) {
      state[couponId].selectedSystem = 0;
    }
    state[couponId].rSystem = null;
    state[couponId].uSystem = null;
    state[couponId].reduceModeCount = reduceModeCount;
  }
  state[couponId].isModified = false;
  state[couponId].xpertAnalysisAuthorId = null;
};
const resetCouponBoard = _ref9 => {
  let {
    state,
    couponId,
    boardIndex,
    keepUserSigns
  } = _ref9;
  const board = state[couponId].boards[boardIndex];
  if (board) {
    board.pixSize = null;
    board.pixVariant = null;
    if (!keepUserSigns) {
      for (const row of board.userSigns) {
        for (let i = 0; i < row.length; ++i) {
          row[i] = false;
        }
      }
    }
    for (const row of board.signs) {
      for (let i = 0; i < row.length; ++i) {
        row[i] = false;
      }
    }
    for (const row of board.signDistribution) {
      for (let i = 0; i < row.length; ++i) {
        row[i] = 0;
      }
    }
    for (let i = 0; i < board.mSigns.length; ++i) {
      board.mSigns[i] = false;
    }
    for (let i = 0; i < board.uSigns.length; ++i) {
      board.uSigns[i] = null;
    }
  }
  const _selectCouponEmpty = () => {
    for (const board of state[couponId].boards) {
      for (const row of board.signs) {
        if (row.includes(true)) {
          return false;
        }
      }
    }
    return true;
  };
  if (_selectCouponEmpty()) {
    state[couponId].xpertAnalysisAuthorId = null;
  }
};
const makeCouponReducer = coupons => createReducer(coupons, builder => {
  builder.addCase(setExternalCoupon, (state, action) => {
    const {
      productId,
      drawId,
      boards,
      betAmount,
      engineType,
      totalAmountOfRows,
      guid
    } = action.payload;
    const couponId = "".concat(productId, "_").concat(drawId, "_").concat(COUPON_EXTERNAL_SYSTEM);
    state[couponId].boards = [...boards];
    state[couponId].betAmount = betAmount;
    state[couponId].engineType = engineType;
    state[couponId].totalAmountOfRows = totalAmountOfRows;
    state[couponId].guid = guid;
  }).addCase(toggleAlternative, (state, action) => {
    const {
      couponId,
      boardIndex,
      event,
      outcome,
      systemType
    } = action.payload;
    const board = state[couponId].boards[boardIndex];
    if (!board) {
      return;
    }
    const isUSystem = systemType === SYSTEM_USYS;
    const isSelected = board.signs[event][outcome] === true;
    const hasMoreThanOneSelection = board.signs[event].filter(sign => sign).length > 1;
    const hasNoUSign = board.uSigns[event] === null;
    const hasNoMSign = board.mSigns[event] === false;
    const {
      type
    } = board;
    if (isUSystem && isSelected && hasMoreThanOneSelection && hasNoUSign && hasNoMSign) {
      board.uSigns[event] = outcome;
    } else {
      const currentSign = helperGetSigns(state, {
        couponId,
        board: boardIndex,
        event
      })[outcome];
      const newSign = !currentSign;
      if (type === BOARD_SINGLE) {
        for (let i = 0; i < board.signs[event].length; ++i) {
          board.signs[event][i] = false;
        }
        for (let i = 0; i < board.userSigns[event].length; ++i) {
          board.userSigns[event][i] = false;
        }
      } else if (type !== BOARD_MULTIPLE) {
        throw new Error("Unknown board type: ".concat(type));
      }
      board.uSigns[event] = null;
      board.signs[event][outcome] = newSign;
      board.userSigns[event][outcome] = board.signs[event][outcome];
      state[couponId].xpertAnalysisAuthorId = null;
      if (currentSign) {
        board.signDistribution[event][outcome] = 0;
      }
      const totalSigns = board.signs[event].length;
      const totalToggledSigns = board.signs[event].filter(sign => sign).length;
      const ratio = totalToggledSigns / totalSigns;
      for (let i = 0; i < board.signDistribution[event].length; ++i) {
        if (board.signs[event][i]) {
          board.signDistribution[event][i] = ratio;
        }
      }
    }
    if ([QUICKPICK_PARTIAL, QUICKPICK_WEIGHTED].indexOf(board.pixVariant) !== -1) {
      board.pixVariant = QUICKPICK_MODIFIED;
    }
    const hasSelectedSign = state[couponId].boards.some(board => board.signs.some(event => event.some(sign => sign)));
    state[couponId].isModified = hasSelectedSign;
    const mSign = helperGetMSign(state, {
      couponId,
      board: boardIndex,
      event
    });
    if (mSign) {
      const activeSigns = helperGetSigns(state, {
        couponId,
        board: boardIndex,
        event
      }).filter(sign => sign);
      if (activeSigns.length < 2) {
        helperToggleMSign(state, {
          couponId,
          board: boardIndex,
          event
        });
      }
    }
  }).addCase(toggleOutcome, (state, action) => {
    const {
      couponId,
      boardIndex,
      eventNumber,
      outcome,
      outcomeGroupIndex
    } = action.payload;
    const board = state[couponId].boards[boardIndex];
    const eventIndex = eventNumber - 1;
    if (!board) {
      return;
    }
    const {
      type
    } = board;
    toggleBitmapValue(board.signs[eventIndex][outcomeGroupIndex], outcome);
    toggleBitmapValue(board.userSigns[eventIndex][outcomeGroupIndex], outcome);
    if (type === BOARD_SINGLE) {
      for (let i = 0; i < board.signs[eventIndex][outcomeGroupIndex].length; ++i) {
        board.signs[eventIndex][outcomeGroupIndex][i] = false;
      }
      for (let i = 0; i < board.userSigns[eventIndex].length; ++i) {
        board.userSigns[eventIndex][i] = false;
      }
    } else if (type !== BOARD_MULTIPLE) {
      throw new Error("Unknown board type: ".concat(type));
    }
    const couponSigns = state[couponId].boards.map(board => board.signs.map(eventSigns => eventSigns.map(signs => hasBitmapValues(signs)))).flat(2);
    state[couponId].isModified = Boolean(couponSigns.find(Boolean));
    state[couponId].xpertAnalysisAuthorId = null;
    if ([QUICKPICK_PARTIAL, QUICKPICK_WEIGHTED].indexOf(board.pixVariant) !== -1) {
      board.pixVariant = QUICKPICK_MODIFIED;
    }
  }).addCase(toggleReduction, (state, action) => {
    const {
      couponId,
      eventNumber,
      reduction,
      boardIndex = 0
    } = action.payload;
    const eventIndex = eventNumber - 1;
    const reductions = state[couponId].boards[boardIndex].reduction;
    const currentReduction = reductions[eventIndex];
    reductions[eventIndex] = toggleBitValue(currentReduction, reduction);
    const productId = state[couponId].productId;
    const engineDefinition = getDefinitionByProduct(productId);
    const engineReductionValue = engineDefinition.reduction.reduce((sum, value) => sum + value, 0);
    const couponReduction = state[couponId].boards[boardIndex].reduction;
    const isReduction = couponReduction.find(eventReductionValue => eventReductionValue < engineReductionValue);
    state[couponId].selectedSystem = isReduction ? 1 : 0;
  }).addCase(setSignDistribution, (state, action) => {
    const {
      couponId,
      board,
      event,
      distributions
    } = action.payload;
    state[couponId].boards[board].signDistribution[event] = distributions;
  }).addCase(selectBetAmount, (state, action) => {
    state[action.payload.couponId].betAmount = action.payload.betAmount;
  }).addCase(setSportkryssFactorIndex, (state, action) => {
    state[action.payload.couponId].sportkryss = action.payload.sportkryssFactorIndex;
  }).addCase(setSportkryssNumber, (state, action) => {
    const {
      couponId,
      sportkryssNumber
    } = action.payload;
    state[couponId].sportkryssNumber = sportkryssNumber;
  }).addCase(clearCoupon, (state, action) => {
    const {
      couponId
    } = action.payload;
    resetCoupon({
      state,
      couponId
    });
  }).addCase(clearCouponBoard, (state, action) => {
    const {
      couponId,
      boardIndex
    } = action.payload;
    resetCouponBoard({
      state,
      couponId,
      boardIndex
    });
  }).addCase(selectWeek, (state, action) => {
    state[action.payload.couponId].week = action.payload.week;
  }).addCase(selectPixSize, (state, action) => {
    const {
      couponId
    } = action.payload;
    state[couponId].selectedAddonPixIndex = action.payload.addonPixChoice;
    resetCoupon({
      state,
      couponId
    });
  }).addCase(loadDrawsLite.fulfilled, (state, action) => {
    const {
      relatedProductId,
      type
    } = action.meta.arg;
    const productName = products.getProductClassName(relatedProductId);
    const engineDefinition = definitions[productName];
    for (const drawId of action.payload.Draws.ids) {
      if (type !== relatedTypes.PIX) {
        break;
      }
      const _draw = action.payload.Draws.entities[drawId];
      const draw = {
        productId: _draw.productId,
        drawNumber: _draw.drawNumber,
        sportType: 1,
        drawEvents: new Array(13).fill(null)
      };
      const drawNumber = action.payload.Draws.entities[drawId].drawNumber;
      const newCoupon = createPixCoupon({
        draw,
        engineDefinition
      });
      const oldCoupon = state["".concat(relatedProductId, "_").concat(drawNumber, "_").concat(COUPON_XPERT)] || {};
      state["".concat(relatedProductId, "_").concat(drawNumber, "_").concat(COUPON_XPERT)] = _objectSpread(_objectSpread({}, newCoupon), oldCoupon);
    }
  }).addCase(selectSystem, (state, action) => {
    var _action$payload$rSyst, _action$payload$uSyst;
    state[action.payload.couponId].selectedSystem = action.payload.system;
    state[action.payload.couponId].rSystem = (_action$payload$rSyst = action.payload.rSystem) !== null && _action$payload$rSyst !== void 0 ? _action$payload$rSyst : state[action.payload.couponId].rSystem;
    state[action.payload.couponId].uSystem = (_action$payload$uSyst = action.payload.uSystem) !== null && _action$payload$uSyst !== void 0 ? _action$payload$uSyst : state[action.payload.couponId].uSystem;
    if (action.payload.system !== action.payload.systemTypes.indexOf(SYSTEM_MSYS)) {
      state[action.payload.couponId].lastSelectedSystemType = action.payload.system;
    }
  }).addCase(toggleMSign, (state, action) => {
    const {
      couponId,
      board,
      event
    } = action.payload;
    helperToggleMSign(state, {
      couponId,
      board,
      event
    });
    state[couponId].boards[board].uSigns[event] = null;
    state[couponId].xpertAnalysisAuthorId = null;
  }).addCase(setReduceModeCount, (state, action) => {
    state[action.payload.couponId].reduceModeCount = action.payload.value;
  }).addCase(loadOpenedDrawsByEngine.fulfilled, (state, action) => _objectSpread(_objectSpread({}, createCoupons({
    engineDefinition: action.meta.arg,
    draws: action.payload.Draws.entities
  })), state)).addCase(placeWager.fulfilled, (state, action) => {
    const {
      error,
      sportkryssNumber,
      relatedCouponId,
      shouldPersistBet
    } = action.payload;
    if (!error) {
      var _action$meta;
      const {
        couponId
      } = ((_action$meta = action.meta) === null || _action$meta === void 0 ? void 0 : _action$meta.arg) || {};
      const {
        productId
      } = state[couponId];
      const isExternal = couponId.includes(COUPON_EXTERNAL_SYSTEM);
      const productName = products.getProductClassName(productId);
      const engineDefinition = definitions[productName];
      const {
        betAmount,
        reduceModeCount
      } = (engineDefinition === null || engineDefinition === void 0 ? void 0 : engineDefinition.defaultCouponValues) || {};
      if (shouldPersistBet && !isExternal) {
        return;
      }
      if (isExternal) {
        state[couponId].boards = [];
      } else {
        if (relatedCouponId) {
          state[relatedCouponId].selectedAddonPixIndex = 0;
          resetCoupon({
            state,
            couponId: relatedCouponId,
            resetFullCoupon: true,
            sportkryssNumber,
            reduceModeCount,
            betAmount
          });
        }
        resetCoupon({
          state,
          couponId,
          resetFullCoupon: true,
          reduceModeCount,
          sportkryssNumber,
          betAmount
        });
      }
    }
  }).addCase(addCouponValues, (state, action) => {
    const {
      couponId,
      signs
    } = action.payload;
    for (let boardIndex = 0; boardIndex < Math.min(signs.length, state[couponId].boards.length); ++boardIndex) {
      const board = state[couponId].boards[boardIndex];
      for (let eventIndex = 0; eventIndex < board.signs.length; ++eventIndex) {
        for (let outcomeGroupIndex = 0; outcomeGroupIndex < board.signs[eventIndex].length; ++outcomeGroupIndex) {
          const currentValue = board.signs[eventIndex][outcomeGroupIndex];
          const newValue = arrayToBitmap(signs[boardIndex].signs[eventIndex][outcomeGroupIndex]);
          board.signs[eventIndex][outcomeGroupIndex] = combine(currentValue, newValue);
        }
      }
    }
    const hasSelectedSign = state[couponId].boards.some(board => board.signs.some(event => event.some(sign => hasBitmapValues(sign))));
    state[couponId].isModified = hasSelectedSign;
  }).addCase(resetWithCouponValues, (state, action) => {
    var _action$meta2, _engineDefinition$def;
    const {
      couponId,
      reduceModeCount,
      sportkryssNumber
    } = action.payload;
    const {
      relatedCouponId,
      productId
    } = ((_action$meta2 = action.meta) === null || _action$meta2 === void 0 ? void 0 : _action$meta2.arg) || {};
    const isExternal = couponId.includes(COUPON_EXTERNAL_SYSTEM);
    const productName = products.getProductClassName(productId);
    const engineDefinition = definitions[productName];
    const betAmount = engineDefinition === null || engineDefinition === void 0 || (_engineDefinition$def = engineDefinition.defaultCouponValues) === null || _engineDefinition$def === void 0 ? void 0 : _engineDefinition$def.betAmount;
    if (isExternal) {
      state[couponId].boards = [];
    } else {
      if (relatedCouponId) {
        state[relatedCouponId].selectedAddonPixIndex = 0;
        resetCoupon({
          state,
          couponId: relatedCouponId,
          resetFullCoupon: true,
          sportkryssNumber,
          reduceModeCount,
          betAmount
        });
      }
      resetCoupon({
        state,
        couponId,
        resetFullCoupon: true,
        sportkryssNumber,
        reduceModeCount,
        betAmount
      });
    }
  }).addMatcher(isAnyOf(generateSportPixCoupon.fulfilled), (state, action) => {
    const {
      couponId,
      boardSigns,
      overwriteCoupon = true,
      keepUserSigns,
      rSystem,
      system,
      xpertAnalysisAuthorId
    } = action.payload;
    if (overwriteCoupon) {
      resetCoupon({
        state,
        couponId,
        keepUserSigns
      });
    }
    for (const [boardIndex, signs] of Object.entries(boardSigns)) {
      var _action$meta$arg$data, _action$meta3;
      if (!overwriteCoupon) {
        resetCouponBoard({
          state,
          couponId,
          keepUserSigns,
          boardIndex
        });
      }
      const board = state[couponId].boards[boardIndex];
      board.signs = signs;
      board.pixSize = (_action$meta$arg$data = (_action$meta3 = action.meta) === null || _action$meta3 === void 0 || (_action$meta3 = _action$meta3.arg) === null || _action$meta3 === void 0 || (_action$meta3 = _action$meta3.data) === null || _action$meta3 === void 0 ? void 0 : _action$meta3.system) !== null && _action$meta$arg$data !== void 0 ? _action$meta$arg$data : null;
      for (let eventIndex = 0; eventIndex < board.signs.length; ++eventIndex) {
        const totalSigns = board.signs[eventIndex].length;
        const totalToggledSigns = board.signs[eventIndex].filter(sign => sign).length;
        const ratio = totalToggledSigns / totalSigns;
        for (let k = 0; k < board.signDistribution[eventIndex].length; ++k) {
          if (board.signs[eventIndex][k]) {
            board.signDistribution[eventIndex][k] = ratio;
          }
        }
      }
      const hasUserSigns = Array.isArray(board.userSigns) && board.userSigns.some(userSignRow => Array.isArray ? userSignRow.includes(true) : userSignRow);
      board.pixVariant = hasUserSigns ? QUICKPICK_PARTIAL : QUICKPICK_WEIGHTED;
    }
    state[couponId].xpertAnalysisAuthorId = xpertAnalysisAuthorId;
    state[couponId].isModified = true;
    if (typeof system === 'number') {
      state[couponId].selectedSystem = system;
    }
    if (rSystem) {
      state[couponId].rSystem = rSystem;
    }
  }).addMatcher(isAnyOf(setCouponValues, resetWithCouponValues), (state, action) => {
    var _state$couponId4;
    const {
      week,
      betAmount,
      sportkryss,
      sportkryssNumber,
      signs,
      systemType,
      rSystem,
      uSystem,
      couponId,
      reduction
    } = action.payload;
    if (week !== undefined) {
      state[couponId].week = week;
    }
    if (betAmount !== undefined) {
      state[couponId].betAmount = betAmount;
    }
    if (sportkryss !== undefined) {
      state[couponId].sportkryss = sportkryss;
    }
    if (sportkryssNumber) {
      state[couponId].sportkryssNumber = sportkryssNumber;
    }
    if (((_state$couponId4 = state[couponId]) === null || _state$couponId4 === void 0 ? void 0 : _state$couponId4.engineType) === engineCouponSelectorType.BINARY) {
      setCouponValuesBinary({
        state,
        couponId,
        signs,
        reduction
      });
    } else {
      setCouponValuesMatrix({
        state,
        couponId,
        signs
      });
    }
    if (typeof systemType !== 'undefined') {
      state[couponId].lastSelectedSystemType = systemType;
      state[couponId].selectedSystem = systemType;
      state[couponId].rSystem = rSystem;
      state[couponId].uSystem = uSystem;
    }
  });
});
setGlobal('svs.components.tipsen.engine.reducers.makeCouponReducer', makeCouponReducer);

 })(window);