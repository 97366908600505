(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/competition-helpers.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/competition-helpers.js');
"use strict";

const competitionsSupportedForProduct = definition => definition.competitions.some(competition => competition.system && competition.systemSize);
const isCompetitionSupported = (definition, competition) => definition.competitions.find(comp => competition.details.systemType === comp.system && competition.details.systemNumber === comp.systemSize);
const getSystemSizeForSupportedCompetition = (definition, competition) => {
  var _isCompetitionSupport;
  return (_isCompetitionSupport = isCompetitionSupported(definition, competition)) === null || _isCompetitionSupport === void 0 ? void 0 : _isCompetitionSupport.systemSize;
};
setGlobal('svs.components.tipsen.engine.utils.competitionHelpers', {
  competitionsSupportedForProduct,
  getSystemSizeForSupportedCompetition,
  isCompetitionSupported
});

 })(window);