(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/get-reducers.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/get-reducers.js');
"use strict";

const {
  makeCouponReducer
} = svs.components.tipsen.engine.reducers;
const getReducers = coupons => {
  const reducers = {
    Coupons: makeCouponReducer(coupons)
  };
  return reducers;
};
setGlobal('svs.components.tipsen.engine.getReducers', getReducers);

 })(window);