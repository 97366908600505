(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/selectors/select-definition.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/selectors/select-definition.js');
"use strict";

const {
  createSelector
} = RTK;
const {
  products
} = svs.utils;
const selectEngineKey = state => state.Module.engine;
const selectEngineDefinition = createSelector(selectEngineKey, engineKey => svs.components.tipsen.engine.definitions[engineKey]);
const selectEngineDefinitionByProductId = productId => svs.components.tipsen.engine.definitions[products.getProductClassName(productId)];
setGlobal('svs.components.tipsen.engine.selectors', {
  selectEngineDefinition,
  selectEngineDefinitionByProductId,
  selectEngineKey
});

 })(window);