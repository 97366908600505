(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/services.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/services.js');
"use strict";

const {
  getSpecificDrawName
} = svs.utils.products;
const {
  jupiter
} = svs.core;
const {
  signIndexToOutcome
} = svs.components.sport.tipsenShared;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  SportPixWagerSystemTypes
} = svs.components.tipsen.engine.constants;
const {
  selectEngineDefinitionByProductId
} = svs.components.tipsen.engine.selectors;
const SportPixFlavour = {
  Balanced: 'Balanced',
  Safe: 'Safe'
};
const normalizeRow = (event, eventTypeId) => {
  const signsForEventType = Object.values(signIndexToOutcome[eventTypeId]);
  const signsForEventTypeLength = signsForEventType.length;
  const row = new Array(signsForEventTypeLength).fill(false);
  signsForEventType.forEach((sign, signIndex) => {
    const foundSign = Boolean(event.outcomes.find(el => el.value === sign));
    row[signIndex] = foundSign;
  });
  return row;
};
const normalizeBoard = (pix, board, eventTypeId) => {
  pix.events.forEach(event => {
    const eventIndex = event.eventNumber - 1;
    const row = normalizeRow(event, eventTypeId);
    board[eventIndex] = row;
  });
  return board;
};
const normalizeResponse = (res, systemType, eventTypeId) => {
  const boards = [];
  if (systemType === SportPixWagerSystemTypes.SROW) {
    res.pixes.forEach(pix => {
      let board = new Array(res.pixes[0].events.length).fill(null);
      board = normalizeBoard(pix, board, eventTypeId);
      boards.push(board);
    });
  } else {
    let board = new Array(res.pix.events.length).fill(null);
    board = normalizeBoard(res.pix, board, eventTypeId);
    boards.push(board);
  }
  return boards;
};

const generateSportPixService = _ref => {
  let {
    productId,
    drawNumber,
    flavour = SportPixFlavour.Safe,
    skewPercentage,
    size,
    guaranteedEvents = {
      events: []
    },
    systemType = SportPixWagerSystemTypes.XPERT_WAGER_MSYS,
    numRows
  } = _ref;
  return new Promise((resolve, reject) => {
    var _definition$outcomes$;
    if (!productId) {
      throw new Error('productId is required');
    }
    if (!drawNumber) {
      throw new Error('drawNumber is required');
    }
    if (!size) {
      throw new Error('size is required');
    }
    const definition = selectEngineDefinitionByProductId(productId);
    const productDrawName = getSpecificDrawName(productId);
    const path = "/wager/1/".concat(productDrawName, "/pix").concat(systemType === SportPixWagerSystemTypes.SROW ? '/singlerows' : '');
    const pixType = definition.pixType;
    const flavourValue = "".concat(flavour).concat(pixType);
    const params = new URLSearchParams();
    params.append('drawNumber', drawNumber);
    params.append('flavour', flavourValue);
    params.append('systemNumberMapping', size);
    if (systemType !== SportPixWagerSystemTypes.SROW) {
      params.append('systemType', systemType);
    } else {
      params.append('numRows', numRows);
    }
    if (skewPercentage) {
      const minSkewPercentage = 0;
      const maxSkewPercentage = 100;
      params.append('skewPercentage', Math.min(Math.max(parseInt(skewPercentage, 10), minSkewPercentage), maxSkewPercentage));
    }
    const eventTypeId = (_definition$outcomes$ = definition === null || definition === void 0 ? void 0 : definition.outcomes.eventTypeId) !== null && _definition$outcomes$ !== void 0 ? _definition$outcomes$ : EventTypeId.EVENT_1X2;
    jupiter.post({
      path: "".concat(path, "?").concat(params.toString()),
      data: guaranteedEvents
    }, res => {
      const normalizedResponse = normalizeResponse(res, systemType, eventTypeId);
      resolve(normalizedResponse);
    }, error => {
      reject(error.responseJSON.error);
    });
  });
};
setGlobal('svs.components.tipsen.engine.services', {
  generateSportPixService
});

 })(window);