(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/utils/get-definition-by-product.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/utils/get-definition-by-product.js');
"use strict";

let definitions;
if (svs.isServer) {
  definitions = require('../definitions');
} else {
  definitions = svs.components.tipsen.engine.definitions;
}
const getDefinitionByProduct = inProductId => {
  for (const definition of Object.values(definitions)) {
    for (const productId of definition.drawProductIds) {
      if (productId === inProductId) {
        return definition;
      }
    }
  }
};
if (svs.isServer) {
  module.exports = getDefinitionByProduct;
} else {
  setGlobal('svs.components.tipsen.engine.utils.getDefinitionByProduct', getDefinitionByProduct);
}

 })(window);